.DraggableCard {
    /* Disabling Selecting Text in the Card*/ 
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    background-color: #fcfcfc !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    border: solid;
    border-width: 1px;
    border-color: #dadada;
}

.ProgContainer {
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin-top: 2em;
}

.ProgInformation {
    background-color: #ececec;
    padding: 0.25em;
    border-radius: 5px;
    font-size: 0.9em;
}

.SettingCard {
    margin-top: 1em;
}

.SettingCard .title{
    margin: 0;
    font-weight: 500;
}

.Sticky2Bottom {
    position: sticky; 
    bottom: -12px;
}
