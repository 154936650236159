html, body {
    height: 100%;
    padding: 0;
    margin: 0;
}
body {
    background-color: #85FFBD;
    background-image: url('../images/login-bg4.jpg');

    background-repeat: no-repeat;
    background-size: cover;
    overflow:hidden;
}

.LoginForm {
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(30px);
    border-radius: 0.5em;
    display: flex;
    padding: 2em;
    margin-top: 10%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

img {
    width: 90%;
    padding-bottom: 2em;
}

.errorMessage {
    animation: erroranimation 0.5s ease-in-out;
}

@keyframes erroranimation {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
  }