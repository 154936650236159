.Logo {
    width: 20em;
    margin: 0;
    padding: 0;
    padding-top: 3em;
}

.LogoWhite {
    width: 10em;
    margin: 0;
    padding: 0;
    padding-top: 0.3em;
    padding-right: 1em;
}

.HeaderText {
    margin: 0;
    padding: 0;
    padding-top: 0.3em;
}

.HeaderDivider {
    width: 1px;    
    margin-top: 0;
    padding: 0;
    height: 100%;
    padding-right: 1em;
    border-style: solid;
    border-width: 0px 0px 0px 1px;
    border-color: RGBA(255,255,255,0.4);
}

.WarningWindow {
    border-style: solid;
    border-width: 2px;
    border-color: RGBA(255,152,0,0.3);
}

.MobileDrawler {
    z-index: 9999;
}