.TargetDiv {
    padding: 0;
    margin: 0;
    height: auto;
}


.TargetCanvas {
    padding: 0;
    margin: 0;
    width: 75%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 50%;
}

@media screen and (max-width: 1500px) {
    .TargetCanvas {
        width: 90%;
    }
  }


  @media screen and (max-width: 1250px) {
    .TargetCanvas {
        width: 100%;
    }
  }

  @media screen and (max-width: 1017px) {
    .TargetCanvas {
        width: 75%;
    }
  }

  @media screen and (max-width: 750px) {
    .TargetCanvas {
        width: 100%;
    }
  }