.HeadSection {
    padding: 0.5em;
    padding-left: 1em;
    margin: 2em;
    margin-top: 0em;
    margin-bottom: 0;
    padding-bottom: 0;
}

.cont {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

.divitem {
    padding: 0.5em;
    padding-left: 1em;
    margin: 2em;
    height: auto;
    width: auto;
    /* border-radius: 2.5px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px; */
}

.MoreInfoButton {
    margin: 0.5em;
    margin-left: 1em;
}

.error {
    padding: 5%;
    margin: auto;
    width: 50%;
}

.table {
    border: 5px;
    padding: 1px;
}

.HoverRow:hover {
    background-color: #d6d6d6;
    cursor: pointer;
}

.TableContainer {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
}

@media (max-width: 500px) {
    .cont, .HeadSection, .divitem {
        margin: 0;
    }
  }
