/* Custom Cards */
/* Float four columns side by side */
.column {
    float: left;
    width: 25%;
    padding: 0 10px;
  }
  
  /* Remove extra left and right margins, due to padding */
  .row {margin: 0 -5px;}
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive columns */
  @media screen and (max-width: 750px) {
    .column {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
  }
  
  /* Style the counter cards */
.cardstats {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 16px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 3px;
  }

.cardstats b {
    font-size: 2.5em;
    padding: 0;
    margin: 0;
}

.cardstats p {
    font-size: 1.25em;
    padding: 0;
    margin: 0;
}

.cardstats img {
  padding: 0;
  margin: 0;
  margin-bottom: 0.25em;
  max-width: 5vh;
  filter: invert(10%) sepia(0%) saturate(4308%) hue-rotate(155deg) brightness(97%) contrast(77%);
}