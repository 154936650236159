a {
  text-decoration: none;
  color:inherit;
}

a:link {
  color:inherit;
}

.homecard{
  cursor: pointer;
  margin: 2.5em;
  padding: 0.5em;
}

.homecard:hover{
  -webkit-box-shadow: 2px 2px 7px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 2px 2px 7px 0px rgba(0,0,0,0.25);
  box-shadow: 2px 2px 7px 0px rgba(0,0,0,0.25);
}

.postPage, .leftSide {
  width: 100%;
}

.comment {
  padding: 0.25em;
  margin: 0.5em;
}

canvas {
  margin: 2em;
}