.InfoDiv {
    border: dotted 2px gray;
    margin-top: 2em;
    margin-bottom: 1em;
    padding: 1em;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fcfcfc;
}

.iconSpaceRight {
    margin-right: 0.5em;
}

.placeHolderText {
    color: #464646;
}

input[type='file'] {
    opacity: 0; /* make transparent */
    z-index: -1; /* move under anything else */
    position: absolute; /* don't let it take up space */
}

input[type="file"] + label {
    padding: 0.5em;
    background: #d6d6d6;
    border-radius: 3px;
    cursor: pointer;
    user-select: none;
    border: solid 0.5px #a1a1a1;
    box-shadow: 5px 4px 86px -6px rgba(0,0,0,0.5);
    -webkit-box-shadow: 5px 4px 6px -6px rgba(0,0,0,0.5);
    -moz-box-shadow: 5px 4px 6px -6px rgba(0,0,0,0.5);
}
input[type="file"]:hover + label {
    
    background: #e6e6e6;
}
