.rsw-editor {
    overflow-y: auto;
}

.rsw-toolbar {
    position: sticky;
    top: 0;
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  .rsw-editor {
    scrollbar-width: auto;
    scrollbar-color: #bebebe #ffffff;
  }

  /* Chrome, Edge, and Safari */
  .rsw-editor::-webkit-scrollbar {
    width: 16px;
  }

  .rsw-editor::-webkit-scrollbar-track {
    background: #ffffff;
  }

  .rsw-editor::-webkit-scrollbar-thumb {
    background-color: #bebebe;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }